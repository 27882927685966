<template>
  <div class="ChangePwd">
    <div class="title">
      <span>修改密码</span>
    </div>
    <el-form ref="ruleForm" :model="ruleForm" label-width="80px" class="formPwd" :rules="rules">
      <el-form-item label="新密码" prop="password">
        <el-input v-model="ruleForm.password"></el-input>
      </el-form-item>
      <el-form-item label="重复密码" prop="new_password">
        <el-input v-model="ruleForm.new_password"></el-input>
      </el-form-item>
      <el-button type="primary" @click="edit('ruleForm')" class="btnPwd">
        提交
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ChangePwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: "",
        new_password: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        new_password: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    edit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$fecth
            .post("user/changePwd", {
              mobile: this.userInfo.mobile,
              password: this.ruleForm.password,
            })
            .then((res) => {
              if (res) {
                this.$message({
                  showClose: true,
                  message: res,
                  type: "success",
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.ChangePwd {
  background-color: #ffffff;
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
  .formPwd {
    padding: 20px;
    width: 400px;
    min-height: 410px;
    margin: 60px auto;
    .input {
      width: 80%;
      margin-bottom: 20px;
    }
    .button {
      margin-top: 40px;
      width: 93.5%;
    }
    .btnPwd {
      width: 385px;
      margin-left: 15px;
    }
  }
}
</style>
